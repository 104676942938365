import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"
import img from "../images/apropos/apropos_main.jpg"

import portrait_baptiste from "../images/apropos/team/Baptiste.jpg"
import portrait_blandine from "../images/apropos/team/Blandine.jpg"
import portrait_camille from "../images/apropos/team/Camille.jpg"
import portrait_manon from "../images/apropos/team/Manon.jpg"
import portrait_faiz from "../images/apropos/team/Faiz.jpg"
import portrait_lauryn from "../images/apropos/team/Lauryn.jpg"
import portrait_matthieu from "../images/apropos/team/Matthieu.jpg"
import portrait_morgan from "../images/apropos/team/Morgan.jpg"
import portrait_robin from "../images/apropos/team/Robin.jpg"
import portrait_samantha from "../images/apropos/team/Samantha.jpg"
import portrait_sylvain from "../images/apropos/team/Sylvain.jpg"
import portrait_etienne from "../images/apropos/team/Etienne.jpg"
import portrait_alois from "../images/apropos/team/Alois.jpg"

const AboutPage = ({ data }) => {

  return (
    <>
      <Layout>

        <div className="page-wrapper apropos">
          
          <div className="main-image">
            <img src={img}/>
          </div>
          
          <h1>A PROPOS</h1>
          <p>
            Créée en 2023 par Aloïs LECLET, la compagnie Beta met l'expérimentation numérique au coeur de sa pratique. Très tôt initié à la danse et au numérique auquel il reste attaché, le directeur artistique fonde sa recherche sur la rencontre entre l'interprète et la technologie. Avec <Link to="/creations/leroiestmort">Le roi est mort</Link> une première création soutenu par le POC théatre d'Alfortville et la Biennale internationale des arts numériques de la Région Île-de-France NEMO, il crée l'occasion d'allier sur scène ses deux expertises passionnelles pour une première le 2 décembre 2023. Corps & esprit, danse & numérique, réunis pour partir à la rencontre du public.
          </p>
          <br/>
          <h1>L'ÉQUIPE</h1>
          <div className="list-team">
            <div className="list-team-item">
              <img src={portrait_baptiste} />
              <p>Baptiste Ollier</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_blandine} />
              <p>Blandine Vialettes</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_camille} />
              <p>Camille Mezerette</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_manon} />
              <p>Manon Chapuis</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_faiz} />
              <p>Faïz AMED MOUHAMED</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_lauryn} />
              <p>Lauryn CALVO</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_matthieu} />
              <p>Matthieu CONVERT</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_morgan} />
              <p>Morgan GRUVEL</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_robin} />
              <p>Robin SALLAT</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_samantha} />
              <p>Samantha DIDIER</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_sylvain} />
              <p>Sylvain MUSSO</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_etienne} />
              <p>Etienne LORIA</p>
              <p>Artiste chorégraphique</p>
            </div>
            <div className="list-team-item">
              <img src={portrait_alois} />
              <p>Aloïs LECLET</p>
              <p>Directeur Artistique</p>
            </div>
          </div>
          <br />          
          <br />          
          <br />          
          <h1>CRÉDITS</h1>

          <p>Soutiens: Le POC théatre d'Alfortville, La Biennale internationale des arts numérique de la Région Île-de-France NEMO</p>
          <p>Photographie: Matthias DAMAY, Aloïs LECLET</p>
          <p>Prise de vue: Eyla AMOHAR</p>
        </div>
      </Layout>
    </>
  )
}

export default AboutPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
