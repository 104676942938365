import PropTypes from "prop-types"
import React from "react"
import { useState } from "react"
import Video from "./Video"
import { Link } from "gatsby"
import { FaRegEnvelope, FaInstagram } from "react-icons/fa"
import video_mp4 from "../images/middle.mp4"
import video_webm from "../images/middle.webm"

const Layout = ({ background, children }) => {


  //open close mobile nav bar
 
  const [open, setOpen] = useState(false);



  return (
    <div className={background == "true" ? "frame video" : "frame"} >
      
      {(background == "true") && (
        <div className="video-wrapper">
          <div className="position-wrapper">
            <video autoPlay muted loop playsinline className="video-background">
              <source src={video_mp4} type="video/mp4" />
              <source src={video_webm} type="video/mp4" />
            </video>
          </div>
        </div>
      )}

        <div className="header-wrapper">

          <div className="header">

            <Link to="/" className="logo-wrapper">
              <img src="/img/logo_black.png" className="logo"/>
              <h1>COMPAGNIE BÊTA</h1>
              <h2>ALOÏS LECLET</h2>
            </Link>

            <div className="nav">
              <Link to="/creations">Créations</Link>
              <Link to="/actionsculturelles">Actions Culturelles</Link>
              <Link to="/agenda">Agenda</Link>
              <Link to="/apropos">À Propos</Link>
              <Link to="/contact">Contact</Link>
            </div>
            
            <div className={open == true ? "nav-mobile open": "nav-mobile"}>
              <Link to="/creations">Créations</Link>
              <Link to="/actionsculturelles">Actions Culturelles</Link>
              <Link to="/agenda">Agenda</Link>
              <Link to="/apropos">À Propos</Link>
              <Link to="/contact">Contact</Link>
            </div>

            <div className={open == true ? "nav-mobile-button open": "nav-mobile-button"} onClick = {() => setOpen(!open)}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div> 

          </div>

        </div>
        

        <div className="main-wrapper video">
          { children } 
        </div>

        <div className="social">
          <Link to="/contact">
            <FaRegEnvelope />
          </Link>
          
          <a href="https://instagram.com/cie_beta" target="_blank" rel="noopener">
            <FaInstagram />
          </a>
        </div>

    </div> 
  )

}

Layout.propTypes = {
  background: PropTypes.string
}


export default Layout
